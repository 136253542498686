<template>
  <post :post="postData"></post>
</template>

<script>
import postData from '../mock/OurService.json'
import Post from '@/components/Post'
export default {
  name: 'Service',
  components: {
    Post
  },
  data: () => ({
    postData: postData
  })
}
</script>

<style scoped>

</style>
